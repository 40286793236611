
import React from 'react'
import Layout from '../components/layout'
import Featurette from '../components/featurette'
import { 
  Container,
  Row,
  Col
} from 'react-bootstrap'
import Video from '../assets/images/introduction.png'
import IntroVideo from '../assets/images/introduction.mp4'

const Tour = (props) => {
  return (
    <>
      <Layout>
        <section className='highlight-color white-text'>
          <Container className='gr-minh-75 d-flex flex-column justify-content-center'>
            <Row>
              <Col xl={{span:6}}>
                <h1>Simple web-based projects and time-recording.</h1>
                <p>Enterprise 360 is an online service for teams that need project management with staff time recording.</p>
                <p>Enterprise 360 is for those who do not want to spend their lives battling their project software but do need to understand how their team’s time is being spent.</p>
                <p>As it’s online, it’s perfect for those who need to work in collaboration with one another.</p>
              </Col>
              <Col xl={{span:5, offset:1}}>
                <video controls poster={Video} className='w-100 z-depth-3' alt="Intro"><source src={IntroVideo}/></video>
              </Col>
            </Row>
          </Container>
        </section>
        <Featurette className='scrolled'
          title='Basic project information at your fingertips'
          text={[
            'Enterprise 360 is an online service for teams that need project management with staff time recording.',     
            'Enterprise 360 is for those who do not want to spend their lives battling their project software but do need to understand how their team’s time is being spent.',
            'As it’s online, it’s perfect for those who need to work in collaboration with one another.'
          ]}
          image='project.png'
          color='white-color'
          textColor='black-text'
          textOrientation='right'
        />
        <Featurette className='scrolled'
          title='Plan your projects.'
          text={[
            'Enterprise 360 is perfect for teams that want to get away from paper and spreadsheets.',     
            'For each project you can construct a simple resource plan and securely upload files and documents.',
            'Each team member gets a personalised login and can view just those projects that you want them to.'
          ]}
          image='projects.png'
          color='dark-color'
          textColor='white-text'
          textOrientation='left'

        />
        <Featurette className='scrolled'
          title='Easy time entry.'
          text={[
            'Enterprise 360 features a simple-to-use daily timesheet system.',     
            'Paper-based time-sheets are frustratingly time-consuming to manage while electronic timesheets are usually over-complex for the project members to understand.',
            'Enterprise 360 benefits from a simple interface and has been designed to allow timesheet entry with the fewest clicks possible. Users only need to enter time spent on a project and can then get back to their real work.'
          ]}
          image='timesheet.png'
          color='white-color'
          textColor='black-text'
          textOrientation='right'
        />
        <Featurette className='scrolled'
          title='Nothing to install. No hardware to buy.'
          text={[
            'Enterprise 360 is securely accessible online from anywhere and on any device.',     
            'Your system is secured by our use of a combination of Rackspace managed hosting and the Oracle database.',
            'Regular back-ups ensure that your data is safe.'
          ]}
          image='browser.png'
          color='mid-color'
          textColor='black-text'
          textOrientation='left'
        />
        <Featurette className='scrolled'
          title='Free email and phone support during workdays.'
          text={[
            'You need to know that should you need help, you want to get in touch with us straight away. That’s why support by email or phone is free during workday hours.',     
            'We don’t have support queues and if you phone us you will speak directly to a technical person who can help you.'
          ]}
          image='support.png'
          color='white-color'
          textColor='black-text'
          textOrientation='right'
        />
        <Featurette className='scrolled'
          title='Software to suit you'
          text={[
            'We recognise that many project teams have a unique way of working.',     
            'As a result, we have been asked to customize Enterprise 360 many times and we have created specialised design tools that means adding new stuff is fast, reliable and great value for money.',
            'Some people want to add budgets while others want to have a different time sheet. Whatever you need, talk to us and we can usually get you exactly what you need, quickly and at a great price.'
          ]}
          image='add.png'
          color='highlight-color'
          textColor='white-text'
        />
        <Featurette className='scrolled'
          title='Get in touch'
          text={[
            'Enterprise 360 has been used by a range of companies from a variety of industry sectors; from large organizations such as the National Trust to small project based consultancies.',     
            'Get in touch for a demonstration.'
          ]}
          image='next.png'
          color='white-color'
  
          linkText='Contact us'
          link='/contact'
          textOrientation='right'
        />
      </Layout>
    </>
  )
}

export default Tour